<template>
  <div class="order-detail-page">
    <Breadcrumb style="margin-bottom: 20px">
      <BreadcrumbItem to="/user/order">我的订单</BreadcrumbItem>
      <BreadcrumbItem>订单详情</BreadcrumbItem>
    </Breadcrumb>
    <!-- 订单商品信息 -->
    <DetailOrderGoods  />
  </div>
</template>
<script>

import DetailOrderGoods from '@/views/member/order/components/DetailOrderGooods'
export default {
  name: 'OrderDetailPage',
  components: {
    DetailOrderGoods,
  },
  data () {
    return {}
  },
  setup () {

  },
}
</script>
<style scoped lang="less">
.order-detail-page {
  background: #fff;
  height: 100%;
}
</style>
