<template>
  <div class="order-info">
    <h3 style="margin-bottom: 20px">订单详情</h3>
    <div class="steps">
      <Steps :current="stepsNum">
        <Step title="确认订单" :content="orderStatus[0].createDate"></Step>
        <Step title="签合同" :content="orderStatus[1].createDate"></Step>
        <Step title="补仓单" :content="orderStatus[2].createDate"></Step>
        <Step title="开发票" :content="orderStatus[3].createDate"></Step>
        <Step title="完成交易" :content="orderStatus[4].createDate"></Step>
      </Steps>
    </div>

    <!-- 商品列表 -->
    <!-- <h3>订单商品</h3> -->
    <div class="title">
      <span class="icon"></span>
      <span class="text">订单信息&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <span class="">订单号：{{ orderInfo.tradeId }}</span>
    </div>

    <div class="info-box">
      <h4>采购商信息</h4>
      <dl>
        <dt>收货人：</dt>
        <dd>{{ purchaserInfo.name }}</dd>
        <dt>手机号：</dt>
        <dd>
          {{ purchaserInfo.tel }}
        </dd>

        <!-- <dd>{{ orderDetail.receiverContact }}</dd> -->
      </dl>
      <dl>
        <dt>收货单位：</dt>
        <dd>{{ purchaserInfo.receiveUnit }}</dd>
        <dt>固定电话：</dt>
        <dd>{{ purchaserInfo.phone }}</dd>
      </dl>
      <dl>
        <dt>地址：</dt>
        <dd>{{ purchaserInfo.addressDetail }}</dd>
        <!-- <dt>邮箱地址：</dt>
        <dd></dd> -->
      </dl>
    </div>
    <!-- 支付方式及送货时间 -->
    <!-- <h3>支付方式及送货时间</h3> -->
    <div class="info-box">
      <h4>配送信息</h4>
      <dl>
        <dt>配送方式：</dt>
        <dd>上门自提</dd>
        <dt>发货要求：</dt>
        <dd>款到即发</dd>
        <!-- <dt>运费方式：</dt>
        <dd></dd> -->
      </dl>
      <dl>
        <dt>自提地址：</dt>
        <dd>{{ purchaserInfo.warehouseAddress }}</dd>
      </dl>
    </div>
    <!-- 发票信息 -->
    <!-- <h3>发票信息</h3> -->
    <div class="info-box">
      <h4>支付信息</h4>
      <dl>
        <dt>付款方式：</dt>
        <dd>平台转账</dd>
        <!-- <dt>付款时间：</dt>
        <dd></dd> -->
      </dl>
    </div>
    <!-- 商品信息 -->
    <div class="title">
      <span class="icon"></span>
      <span class="text">商品信息</span>
      <!-- <span
        v-if="isEdit"
        style="float: right; color: #409eff"
        @click="save(false)"
      >
        保存
      </span>
      <span v-else-if="currentStatus < 4" style="float: right; color: #409eff" @click="addRow(true)">
        编辑
      </span> -->
    </div>

    <div class="info-box-noborder">
      <Table
        v-if="isEdit"
        border
        :columns="columns"
        :data="productList"
        :fixed-shadow="'auto'"
      >
        <template #goodsName="{}">
          <Input
            v-model="value1"
            disabled
            :placeholder="orderInfo.paymentOrder.goodsName"
          />
        </template>
        <template #formate="{}">
          <Input
            v-model="value1"
            disabled
            :placeholder="orderInfo.paymentOrder.formate"
          />
        </template>
        <template #material="{}">
          <Input
            v-model="value1"
            disabled
            :placeholder="orderInfo.paymentOrder.material"
          />
        </template>

        <template #goodsCount="{}">
          <Input
            v-model="value1"
            :placeholder="orderInfo.paymentOrder.goodsCount"
          />
        </template>
        <template #price="{}">
          <Input v-model="value1" :placeholder="orderInfo.paymentOrder.price" />
        </template>

        <template #tradeType="{}">
          <Input
            v-model="value1"
            disabled
            :placeholder="orderInfo.paymentOrder.tradeType"
          />
        </template>
      </Table>
      <Table v-else border :columns="columns" :data="goodsData"></Table>
    </div>
    <!-- 汇总信息 -->
    <div class="sum-info">
      <div v-if="stepsNum == 0" class="info">
        <div class="item">
          <label>商品总金额</label>
          <span class="price">
            ¥
            <Numeral :value="orderAmount" format="0,0.00"></Numeral>
          </span>
        </div>
      </div>
      <div v-if="currentStatus < 4 && currentStatus >= 3" class="info">
        <div class="item">
          <label>买家未付款</label>
          <span class="price">
            ¥
            <Numeral value="0.00" format="0,0.00"></Numeral>
          </span>
        </div>
      </div>
      <div v-if="currentStatus >= 4 && currentStatus != 7" class="info">
        <div class="item">
          <label>买家已付款</label>
          <span class="price">
            ¥
            <Numeral :value="orderAmount" format="0,0.00"></Numeral>
          </span>
        </div>
      </div>
    </div>
    <!-- 上传合同 -->
    <div v-if="currentStatus === 2 && !isContractCode" class="title">
      <span class="icon"></span>
      <span class="text">上传合同&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <span class="">上传盖章合同附件</span>
    </div>

    <div
      v-if="currentStatus === 2 && !isContractCode"
      class="info-box-noborder"
    >
      <dl>
        <dt>付款方式</dt>
        <dd>
          <RadioGroup v-model="buyType">
            <Radio :label="1">先款后货</Radio>
            <!-- <Radio :label="2">先货后款</Radio> -->
          </RadioGroup>
        </dd>
      </dl>

      <dl>
        <dt>合同文件</dt>
        <dd>
          <Upload
            :before-upload="contractUpload"
            :on-success="contractSuccess"
            :on-remove="contractUploadRemove"
            multiple
            :data="{ path: 'contract' }"
            type="drag"
            action="http://file.qltrade.ixiaoguo.com.cn/file/upload"
          >
            <div style="padding: 20px 0">
              <Icon
                type="ios-cloud-upload"
                size="52"
                style="color: #3399ff"
              ></Icon>
              <p>将文件拖到此处，或点击上传</p>
            </div>
          </Upload>
          <span class="decs-img-text">
            文件大小在6MB以内、支持png，jpeg、pdf格式
          </span>
          <p class="down-img-text">下载电子版合同</p>
        </dd>
        <dt>备注说明</dt>
        <dd>
          <Input
            v-model="memo"
            maxlength="200"
            type="textarea"
            show-word-limit
            style="padding: 10px 0 0 0"
            :rows="4"
            placeholder="请输入备注说明"
          />
        </dd>
      </dl>
    </div>
    <!-- 合同信息 -->
    <div
      v-if="(currentStatus > 2 && currentStatus != 7) || isContractCode"
      class="title"
    >
      <span class="icon"></span>
      <span class="text">合同信息</span>
    </div>
    <div
      v-if="(currentStatus > 2 && currentStatus != 7) || isContractCode"
      class="info-box-noborder"
    >
      <dl>
        <dt>付款方式</dt>
        <dd>先款后货</dd>
      </dl>

      <dl>
        <dt>合同文件</dt>
        <dd>
          <div v-for="item,index in orderInfo.paymentOrder.contractUrl" :key="index">
            <Icon type="ios-paper-outline" />
            <span>
              <!-- {{ orderInfo.paymentOrder.contractCode }}合同.jpg -->
							附件{{index+1}}
              <a
                class="down-img-text"
                style="margin-left: 10px"
                @click="open(orderInfo.paymentOrder.contractUrl[index])"
              >
                查看
              </a>
            </span>
          </div>
        </dd>
      </dl>
      <dl>
        <dt>备注说明</dt>
        <dd>
          {{ orderInfo.paymentOrder.contractMemo }}
        </dd>
      </dl>
    </div>

    <!-- 上传仓单 -->
    <div v-if="currentStatus === 4" class="title">
      <span class="icon"></span>
      <span class="text">上传仓单</span>
    </div>
    <div v-if="currentStatus === 4" class="info-box-noborder">
      <dl>
        <dt>仓单</dt>
        <dd>
          <Upload
            :before-upload="contractUpload"
            :on-success="contractSuccess"
            :on-remove="contractUploadRemove"
            multiple
            :data="{ path: 'payment' }"
            type="drag"
            action="http://file.qltrade.ixiaoguo.com.cn/file/upload"
          >
            <div style="padding: 20px 0">
              <Icon
                type="ios-cloud-upload"
                size="52"
                style="color: #3399ff"
              ></Icon>
              <p>将文件拖到此处，或点击上传</p>
            </div>
          </Upload>
          <span class="decs-img-text">
            文件大小在6MB以内、支持png，jpeg、pdf格式
          </span>
        </dd>
        <dt>备注说明</dt>
        <dd>
          <Input
            v-model="memo"
            maxlength="200"
            type="textarea"
            show-word-limit
            style="padding: 10px 0 0 0"
            :rows="4"
            placeholder="请输入备注说明"
          />
        </dd>
      </dl>
    </div>

    <!-- 仓单信息 -->
    <div v-if="currentStatus > 4 && currentStatus != 7" class="title">
      <span class="icon"></span>
      <span class="text">仓单信息</span>
    </div>
    <div
      v-if="currentStatus > 4 && currentStatus != 7"
      class="info-box-noborder"
    >
      <dl>
        <dt>仓单文件</dt>
        <dd>
          <Icon type="ios-paper-outline" />
          <span>
            仓单.jpg
            <a
              class="down-img-text"
              style="margin-left: 10px"
              @click="open(orderInfo.paymentOrder.warehouseOrderUrl)"
            >
              查看
            </a>
          </span>
        </dd>
      </dl>
      <dl>
        <dt>备注说明</dt>
        <dd>
          {{ orderInfo.paymentOrder.warehouseOrderMemo }}
        </dd>
      </dl>
    </div>

    <!-- 上传发票 -->
    <div v-if="currentStatus === 5" class="title">
      <span class="icon"></span>
      <span class="text">上传发票</span>
    </div>
    <div v-if="currentStatus === 5" class="info-box-noborder">
      <dl>
        <dt>发票</dt>
        <dd>
          <Upload
            :before-upload="contractUpload"
            :on-success="contractSuccess"
            :on-remove="contractUploadRemove"
            multiple
            :data="{ path: 'payment' }"
            type="drag"
            action="http://file.qltrade.ixiaoguo.com.cn/file/upload"
          >
            <div style="padding: 20px 0">
              <Icon
                type="ios-cloud-upload"
                size="52"
                style="color: #3399ff"
              ></Icon>
              <p>将文件拖到此处，或点击上传</p>
            </div>
          </Upload>
          <span class="decs-img-text">
            文件大小在6MB以内、支持png，jpeg、pdf格式
          </span>
        </dd>
        <dt>备注说明</dt>
        <dd>
          <Input
            v-model="memo"
            maxlength="200"
            type="textarea"
            show-word-limit
            style="padding: 10px 0 0 0"
            :rows="4"
            placeholder="请输入备注说明"
          />
        </dd>
      </dl>
    </div>

    <!-- 发票信息 -->
    <div v-if="currentStatus >= 6 && currentStatus != 7" class="title">
      <span class="icon"></span>
      <span class="text">发票信息</span>
    </div>
    <div
      v-if="currentStatus >= 6 && currentStatus != 7"
      class="info-box-noborder"
    >
      <dl>
        <dt>发票文件</dt>
        <dd>
          <Icon type="ios-paper-outline" />
          <span>
            发票.jpg
            <a
              class="down-img-text"
              style="margin-left: 10px"
              @click="open(orderInfo.paymentOrder.payBillUrl)"
            >
              查看
            </a>
          </span>
        </dd>
      </dl>
      <dl>
        <dt>备注说明</dt>
        <dd>
          {{ orderInfo.paymentOrder.pauBillMemo }}
        </dd>
      </dl>
    </div>
    <!-- 确认 -->
    <div class="sum-info">
      <div v-if="currentStatus === 1" class="sum-info">
        <Button class="btn" @click="submit(0)">确认订单</Button>
        <span class="hint">
          温馨提示：请仔细阅读合同，确认交易双方安全。保证资金账户安全
        </span>
      </div>

      <div
        v-if="currentStatus === 2 && orderInfo.paymentOrder.contractCode"
        class="sum-info"
      >
        <Button class="btn" style="background: #909399">合同已上传</Button>
        <span class="hint">
          温馨提示：请仔细阅读合同，确认交易双方安全。保证资金账户安全
        </span>
      </div>
      <div v-else-if="currentStatus === 2" class="sum-info">
        <Button class="btn" @click="submit(1)">确认上传</Button>
        <span class="hint">
          温馨提示：请仔细阅读合同，确认交易双方安全。保证资金账户安全
        </span>
      </div>

      <div v-if="currentStatus == 4" class="sum-info">
        <Button class="btn" @click="submit(2)">确认上传</Button>
        <span class="hint">
          温馨提示：请仔细阅读合同，确认交易双方安全。保证资金账户安全
        </span>
      </div>
      <div v-if="currentStatus === 5" class="sum-info">
        <Button class="btn" @click="submit(3)">确认上传</Button>
        <span class="hint">
          温馨提示：请仔细阅读合同，确认交易双方安全。保证资金账户安全
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { fileUploadLimit } from '@/utils/request.js'
import { ref } from 'vue'
export default {
  name: 'DetailOrderGoods',
  props: {},
  data () {
    return {
      isEdit: false,
      stepsNum: 0,
      currentStatus: 0, // 订单当前状态
      isContractCode: false, // 是否已上传过合同
      columns: [],
      tradeTypeList: [
        {
          value: '全款模式',
          label: '全款模式',
        },
        {
          value: '保证金模式',
          label: '保证金模式',
        },
      ],
      buyType: 1,
      orderInfo: {}, // 订单数据
      // orderStatus: [],
      productList: [],
      purchaserInfo: {}, // 采购商信息
      goodsData: [],
      orderAmount: '', // 总金额
      orderStatus: [
        { createDate: '' },
        { createDate: '' },
        { createDate: '' },
        { createDate: '' },
        { createDate: '' },
      ],
      memo: '', // 各阶段备注
      imgsUrl: '', // 上传的各个图片地址
      imgsUrlArray: [],
    }
  },
  created () {
    this.init()
  },

  methods: {
    init () {
      const params = {
        id: this.$route.params.id,
      }
      this.$store
        .dispatch('PurchasePaymentOrder', params)
        .then((res) => {
          if (res.returnCode === '1') {
            this.goodsData = []
            this.orderInfo = res.result
            this.purchaserInfo = res.result.purchaser
            // this.orderStatus = res.result.orderStatus[0]
            // Steps 数据

            if (res.result.orderStatus.length >= 1) {
              this.stepsNum = res.result.orderStatus.length - 1
            }
            // if(){

            // }
            if (res.result.paymentOrder.contractCode) {
              this.isContractCode = true
            } else {
              this.isContractCode = false
            }
            this.currentStatus = res.result.paymentOrder.orderStatus
            const arr = ref([
              { createDate: '' },
              { createDate: '' },
              { createDate: '' },
              { createDate: '' },
              { createDate: '' },
            ])
            res.result.orderStatus.forEach(function (element, index) {
              arr.value.splice(index, 1, element)
            })
            this.orderStatus = arr.value
          }
        })
        .then(() => {
          this.save()
        })
    },
    /**
     * 编辑模式
     */
    addRow (isEdit) {
      this.isEdit = isEdit
      this.productList = []
      this.columns = [
        {
          title: '品名',
          key: 'goodsName',
          slot: 'goodsName',
          // width: 150,
        },
        {
          title: '规格',
          key: 'formate',
          slot: 'formate',
          // width: 150,
        },
        {
          title: '材质',
          key: 'material',
          slot: 'material',
          // width: 120,
        },
        {
          title: '数量/重量',
          key: 'goodsCount',
          slot: 'goodsCount',
          // width: 120,
        },
        {
          title: '单价',
          key: 'price',
          slot: 'price',
          // width: 120,
        },
        {
          title: '交易模式',
          key: 'tradeType',
          slot: 'tradeType',
          // width: 160,
          className: 'table-info-column',
        },
        // {
        //   title: '货期',
        //   key: 'deliveryDate',
        //   slot: 'deliveryDate',
        //   width: 120,
        // },
        // {
        //   title: '仓库',
        //   key: 'sendFromCityCode',
        //   slot: 'sendFromCityCode',

        // },
      ]
      const tableInfo = {
        // 表格所需字段
        categorySelect: [], // 每行选中的商品分类
        categoryId: '',
        subCategoryId: '',
        goodsName: '', // 每行选中的品名
        formate: '', // 规格
        unitId: '', // 单位id
        unitName: '', // 单位名
        formateOptions: [], // 规格下拉框
        productNameOptions: [], // 品名下拉框
        materialOptions: [], // 材质下拉框
        count: 0, // 数量
        totalPrice: 0, // 总金额
        price: '', // 单价
        priceUnit: '', // 单价单位
        depositId: '', // 保证金Id
        depositValue: '', // 保证金比例
        depositAfterCount: 0, // 保证金数值
        material: '', // 材质
        categoryInvalide: false,
        productNameInvalide: false,
        formateInvalide: false,
        materialInvalide: false,
      }
      this.productList.push(tableInfo)
    },
    save (isEdit) {
      this.isEdit = isEdit
      this.goodsData = []
      this.columns = [
        {
          title: '品名',
          key: 'goodsName',
          width: 150,
        },
        {
          title: '规格',
          key: 'formate',
          width: 150,
        },
        {
          title: '材质',
          key: 'material',
          width: 100,
        },
        {
          title: '数量/重量',
          key: 'goodsCount',
          width: 100,
        },
        {
          title: '单价',
          key: 'price',
          width: 120,
        },
        {
          title: '单位',
          key: 'unitName',
          width: 120,
        },
        {
          title: '交易模式',
          key: 'tradeType',
          width: 120,
          className: 'table-info-column',
        },
        // {
        //   title: '货期',
        //   key: 'deliveryDate',
        //   width: 120,
        // },
        // {
        //   title: '仓库',
        //   key: 'address',
        //   width: 100,
        // },
        {
          title: '总金额',
          key: 'orderAmount',
          fixed: 'right',
          width: 120,
        },
      ]
      this.orderInfo.paymentOrder.tradeType === 2
        ? (this.orderInfo.paymentOrder.tradeType = '保证金模式')
        : (this.orderInfo.paymentOrder.tradeType = '全款模式')

      this.goodsData.push(this.orderInfo.paymentOrder)
      this.orderAmount = this.orderInfo.paymentOrder.orderAmount
    },
    contractUpload (file) {
      const fileValide = fileUploadLimit(file, this.fileLimitSize)
      if (fileValide) {
        this.$Message.error(fileValide)
        return false
      }
      return true
    },

    contractSuccess (response, file, fileList) {
      if (response.returnCode === '1') {
        this.imgsUrl = response.result
        this.imgsUrlArray.push(this.imgsUrl)
        // this.formValidate.certificateImg.push(response.result)
        // this.$refs.formValidate.validateField('logo')
      }
    },
    open (url) {
      window.open(url, '_self')
    },
    contractRemove () {
      this.formValidate.logo = ''
      this.$refs.formValidate.validateField('logo')
    },
    submit (num) {
      switch (num) {
        // 确认订单
        case 0: {
          const postData = ref({
            id: this.orderInfo.id,
            tradeId: this.orderInfo.tradeId,
          })
          this.$store
            .dispatch('FirmPaymentOrder', postData.value)
            .then((res) => {
              if (res.returnCode === '1') {
                this.$router.push('/user/order')
                this.$Notice.success({
                  title: '确认订单成功',
                })
              }
            })
            .then(() => {
              this.save()
            })
          break
        }
        // 上传合同
        case 1: {
          const postData = ref({
            id: this.orderInfo.id,
            tradeId: this.orderInfo.tradeId,
            // tradeType: this.buyType,
            contractMemo: this.memo,
            contractUrl: this.imgsUrlArray,
          })
          // console.log(postData.value)
          this.$store
            .dispatch('PurchaseSignContract', postData.value)
            .then((res) => {
              if (res.returnCode === '1') {
                this.$router.push('/user/order')
                this.$Notice.success({
                  title: '上传合同成功',
                })
              }
            })
            .then(() => {
              this.save()
            })
          break
        }
        // 上传仓单
        case 2: {
          const postData = ref({
            id: this.orderInfo.id,
            tradeId: this.orderInfo.tradeId,
            warehouseOrderMemo: this.memo,
            warehouseOrderUrl: this.imgsUrl,
          })

          this.$store
            .dispatch('PurchaseUploadWarehouseOrder', postData.value)
            .then((res) => {
              if (res.returnCode === '1') {
                this.$router.push('/user/order')
                this.$Notice.success({
                  title: '上传仓单成功',
                })
              }
            })
            .then(() => {
              this.save()
            })
          break
        } // 上传发票
        case 3: {
          const postData = ref({
            id: this.orderInfo.id,
            tradeId: this.orderInfo.tradeId,
            payBillMemo: this.memo,
            payBillUrl: this.imgsUrl,
          })

          this.$store
            .dispatch('PurchaseUploadPaymentBill', postData.value)
            .then((res) => {
              if (res.returnCode === '1') {
                this.$router.push('/user/order')
                this.$Notice.success({
                  title: '上传发票成功',
                })
              }
            })
            .then(() => {
              this.save()
            })
          break
        }

        default:
        //
      }
    },
  },
}
</script>

<style scoped lang="less">
.order-info {
  padding: 0 10px;
  // h3 {
  //   font-weight: normal;
  //   font-size: 16px;
  //   padding: 25px 5px;
  // }
  .title {
    height: 20px;
    line-height: 1;
    margin-bottom: 10px;
    padding: 25px 5px;
    .icon {
      display: inline-block;
      height: 20px;
      width: 5px;
      background-color: #d12b23;
      margin-right: 10px;
      vertical-align: middle;
    }
    .text {
      display: inline-block;
      vertical-align: middle;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
// 商品表格
.goods-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 auto;
  th {
    font-weight: normal;
    line-height: 60px;
    background: #f5f5f5;
    &:first-child {
      text-align: left;
      padding-left: 20px;
    }
  }
  td {
    border-bottom: 1px solid #f5f5f5;
    text-align: center;
    &:first-child {
      padding-left: 20px;
      border-left: 1px solid #f5f5f5;
    }
    &:last-child {
      border-right: 1px solid #f5f5f5;
    }
  }
  .product {
    display: flex;
    padding: 20px 0;
    img {
      width: 70px;
      height: 70px;
      border: 1px solid #f5f5f5;
    }
    .info {
      align-self: center;
      padding-left: 20px;
      text-align: left;
      p {
        margin-bottom: 5px;
        width: 280px;
      }
      .attrs {
        color: #999;
      }
    }
  }
}
// 信息盒子
.info-box {
  border: 1px solid #dcdfe6;
  padding: 10px;
  margin-bottom: 10px;
  dl {
    display: flex;
    line-height: 30px;
    dt {
      width: 80px;
      color: #999;
      font-size: 14px;
      i {
        display: inline-block;
        width: 0.5em;
      }
    }
    dd {
      flex: 1;
    }
  }
}
.info-box-noborder {
  padding: 10px;
  margin-bottom: 10px;
  dl {
    display: flex;
    line-height: 30px;
    dt {
      width: 80px;
      color: #999;
      font-size: 14px;
      i {
        display: inline-block;
        width: 0.5em;
      }
    }
    dd {
      flex: 1;
    }
  }
  .decs-img-text {
    font-size: 12px;
    color: #909399;
  }
  .down-img-text {
    font-size: 14px;
    color: #409eff;
  }
}
// 汇总信息
.sum-info {
  // padding: 0 30px 10px 30px;
  overflow: hidden;
  .hint {
    float: right;
    text-align: center;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    color: #dc9100;
  }
  .btn {
    float: right;
    width: 144px;
    background: #d12b23;
    height: 37px;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    border: 1px solid #cfcdcd;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .info {
    width: 380px;
    // height: 160px;
    float: right;
    font-size: 14px;

    .item {
      line-height: 40px;
      display: flex;
      label {
        text-align: right;
        width: 160px;
        // font-size: 14px;
        i {
          display: inline-block;
          width: 2em;
        }
      }
      span {
        flex: 1;
        text-align: right;
        padding-right: 30px;
        &.price {
          font-size: 20px;
          text-align: right;
          color: @priceColor;
        }
      }
    }
  }
}
.steps {
  height: 73px;
  background: #f8f8fb;
  padding: 20px;
}

/deep/ .ivu-steps-item.ivu-steps-status-process .ivu-steps-head-inner {
  background: #d12b23;
  border-color: #d12b23;
}
/deep/ .ivu-steps .ivu-steps-title {
  background: #f8f8fb;
}
/deep/ .ivu-steps .ivu-steps-head {
  background: #f8f8fb;
}
/deep/ .ivu-upload {
  padding: 10px 20px 0 0;
}
/deep/ .ivu-table td.table-info-column {
  color: #d12b23;
}
/deep/ .ivu-steps-item.ivu-steps-status-finish .ivu-steps-tail > i:after {
  background: #d12b23;
}
/deep/ .ivu-steps-item.ivu-steps-status-finish .ivu-steps-head-inner {
  border-color: #d12b23;
}
/deep/ .ivu-steps-item.ivu-steps-status-finish .ivu-steps-head-inner span,
.ivu-steps-item.ivu-steps-status-finish
  .ivu-steps-head-inner
  > .ivu-steps-icon {
  color: #d12b23;
}
/deep/ .ivu-steps-item.ivu-steps-status-process .ivu-steps-title {
  color: #333333;
  font-size: 16px;
  font-weight: 600;
}
/deep/ .ivu-steps-item.ivu-steps-status-wait .ivu-steps-title {
  color: #999999;
  font-size: 16px;
}
/deep/ .ivu-steps-item.ivu-steps-status-finish .ivu-steps-title {
  color: #333333;
  font-size: 16px;
  font-weight: 600;
}
</style>
